import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
import { inject, observer } from "mobx-react";

mapboxgl.accessToken = `${process.env.GATSBY_MAPBOX_ACCESS_TOKEN}`;

@inject("store")
@observer
class ResultsMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 2.97,
      lat: 46.79,
      zoom: 5,
      hotels: props.hotels,
      container: props.container,
      geojson: null,
      type: props.type,
    };
  }

  componentDidMount() {
    this.createMap();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.type === "search") {
      document.querySelectorAll(".mapboxgl-marker").forEach(function (a) {
        a.remove();
      });
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.hotels = this.props.store.searchResultsHotels;
      this.createMap();
    }
  }

  createHotel() {
    const places = [];
    this.state.hotels.forEach((element) => {
      const newGeoJsonPoint = {
        type: "Feature",
        properties: {
          id: element.id,
          slug: element.slug,
          name: element.title,
          address: element.address,
          phone: element.phone,
          spirit: element.spirit,
          booking_id: element.booking_id,
        },
        geometry: {
          type: "Point",
          coordinates: [element.lon, element.lat],
        },
      };
      places.push(newGeoJsonPoint);
    });
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.geojson = {
      type: "FeatureCollection",
      features: places,
    };
  }

  createMap() {
    this.createHotel();
    const options = {
      container: "map",
      style: `${process.env.GATSBY_MAPBOX_STYLE}`,
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    };

    const map = new mapboxgl.Map(options);

    map.scrollZoom.disable();
    map.addControl(new mapboxgl.NavigationControl());

    this.state.geojson.features.forEach((feature) => {
      // create a HTML element for each feature
      const el = document.createElement("div");
      el.className = "map-marker-" + feature.properties.spirit.slug;
      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el)
        .setLngLat(feature.geometry.coordinates)
        .addTo(map);

      el.addEventListener("click", () => {
        if (this.state.hotels.length > 0) {
          Array.from(
            document.getElementsByClassName("search-results-list-item")
          ).forEach((node) => {
            node.classList.remove("focus");
          });
          document.getElementById(feature.properties.id).className += " focus";
        }
        const coords = feature.geometry.coordinates;
        map.flyTo({
          center: coords,
          speed: 0.2,
        });
      });
    });

    const bounds = new mapboxgl.LngLatBounds();
    if (this.state.hotels.length > 1) {
      this.state.hotels.forEach(function (feature) {
        bounds.extend([feature.lon, feature.lat]);
      });
      map.fitBounds(bounds, {
        padding: 100,
      });
    } else {
      this.state.hotels.forEach(function (feature) {
        bounds.extend([feature.lon, feature.lat]);
      });
      map.fitBounds(bounds, {
        zoom: 11,
      });
    }
  }

  render() {
    return (
      <div className="map">
        <div className="map-content" id="map" />
      </div>
    );
  }
}

export default ResultsMap;
