import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { useIntl } from "react-intl";

import Search from "./search";
import Close from "./close";
import BookModal from "./book-modal";

const withHook = (Component) => {
  return function WrappedComponent(props) {
    const intl = useIntl();
    return <Component {...props} intl={intl} />;
  };
};

@inject("store")
@observer
class Results extends Component {
  static today = new Date();
  static tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
  static defaultStartDate = new Date(Results.today).toISOString().slice(0, 10);
  static defaultEndDate = new Date(Results.tomorrow).toISOString().slice(0, 10);

  constructor(props) {
    super(props);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.state = {
      search: "",
    };
  }

  book(event) {
    event.preventDefault();

    const url = event.target.href;

    if (window.dataLayer) {
      window.dataLayer.push({
        event: "begin_checkout",
        event_category: "outbound",
        eventCallback: () => {
          window.open(url, "_blank");
        },
        eventTimeout: 2000,
      });
    } else {
      window.open(url, "_blank");
    }
  }

  toggleSearch(name, booking_id) {
    if (this.state.search === "") {
      this.props.store.searchQuery = {
        value: name,
        type: "hotel",
        booking_id: booking_id,
      };
      this.setState({
        search: " active",
      });
    } else {
      this.setState({
        search: "",
      });
    }
  }

  render() {
    const data = this.props.data;
    const store = this.props.store;
    const intl = this.props.intl;

    if (data.length > 0) {
      return (
        <div className="search-results">
          <ul className="search-results-list">
            {data.map((hotel, i) => {
              const { startDate, endDate, couponCode } = this.props.store;

              const overnights =
                (new Date(endDate) - new Date(startDate)) / 1000 / 60 / 60 / 24;

              const params = new URLSearchParams({
                id_etab: hotel.booking_id,
                date_deb: intl.formatDate(startDate, {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                }),
                nb_nuit: overnights,
                code_promo: couponCode,
                langue: intl.locale === "fr" ? "francais" : "anglais",
              }).toString();

              const url = `https://reservation.ace-hotel.com/module_booking_engine/index.php?${params}`;

              return (
                <li
                  className={`search-results-list-item search-results-list-item-${hotel.spirit.slug}`}
                  id={hotel.id}
                  key={i}
                >
                  <div className="search-results-list-item-description">
                    <h2>
                      {`ACE Hôtel${
                        hotel.spirit.slug === "travel" ? " Travel" : ""
                      } ${hotel.title}`}
                    </h2>
                    <p>
                      {hotel.address.split(",").map((line, i) => (
                        <Fragment key={i}>
                          {line}
                          <br />
                        </Fragment>
                      ))}
                      {hotel.phone && (
                        <a
                          className="link link-tel"
                          href={`tel:${hotel.phone.replace(/\s/g, "")}`}
                        >
                          {hotel.phone}
                        </a>
                      )}
                    </p>
                  </div>
                  {this.props.type === "offer" ? (
                    hotel.booking_id ? (
                      <button
                        type="button"
                        className="button"
                        onClick={() =>
                          this.toggleSearch(hotel.title, hotel.booking_id)
                        }
                      >
                        {intl.formatMessage({ id: "book" })}
                      </button>
                    ) : (
                      <BookModal />
                    )
                  ) : hotel.booking_id ? (
                    <a className="button" href={url} onClick={this.book}>
                      {intl.formatMessage({ id: "book" })}
                    </a>
                  ) : (
                    <BookModal />
                  )}
                </li>
              );
            })}
          </ul>
          <div className={`modal modal-search${this.state.search}`}>
            <div className="modal-content">
              <button
                className="modal-close"
                onClick={this.toggleSearch}
                aria-label={intl.formatMessage({ id: "close" })}
              >
                <Close />
              </button>
              <div className="container">
                {store.searchQuery.value && store.searchQuery.booking_id ? (
                  <Search
                    preselectedQuery={store.searchQuery.value}
                    preselectedBookingId={store.searchQuery.booking_id}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <button
              className="modal-backdrop"
              onClick={this.toggleSearch}
              aria-label={intl.formatMessage({ id: "close" })}
            ></button>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default withHook(Results);
