import React, { Component } from "react";
import { navigate, graphql } from "gatsby";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";

import Layout from "../components/layout";
import Map from "../components/map";
import ResultsMap from "../components/results-map";
import Results from "../components/results";
import Seo from "../components/seo";
import SearchData from "../components/search-data";
import Pin from "../components/pin";

@inject("store")
@observer
class ResultsTemplate extends Component {
  componentDidMount() {
    if (this.props.store.searchQuery.value === "") {
      navigate("/");
    }
  }

  render() {
    const data = this.props.data;
    const store = this.props.store;
    const allPages = data.allContentfulPage.nodes;

    function getPageTitle() {
      return allPages.find((page) => page.category === "Résultats").title;
    }

    return (
      <Layout noButton>
        <Seo title={getPageTitle()} />
        <section className="section section-white text-center pt-10">
          <div className="search">
            <div className="search-container">
              <h1 className="h3">
                {store.searchResultsHotels.length < 1 ? (
                  <>
                    <FormattedMessage id="results.none" />{" "}
                    <FormattedMessage id="opening-quote" />
                    {store.searchQuery.value}
                    <FormattedMessage id="closing-quote" />
                  </>
                ) : (
                  <>
                    <mark>
                      {store.searchResultsHotels.length}{" "}
                      <FormattedMessage id="results.hotel" />
                      {store.searchResultsHotels.length > 1 ? "s" : ""}{" "}
                    </mark>
                    <FormattedMessage id="results.match" />{" "}
                    <FormattedMessage id="opening-quote" />
                    {store.searchQuery.value}
                    <FormattedMessage id="closing-quote" />
                  </>
                )}
              </h1>
              {store.searchResultsHotels.length < 1 ? (
                <p className="text-blue-dark">
                  <FormattedMessage id="results.map" />
                </p>
              ) : (
                ""
              )}
              <SearchData data={store} />
              <div className="search-legend">
                <ul className="search-legend-list">
                  <li className="search-legend-list-item">
                    <Pin />
                    <span>
                      ACE Hôtel <FormattedMessage id="simplement-bien" />
                    </span>
                  </li>
                  <li className="search-legend-list-item">
                    <Pin variant="travel" />
                    <span>ACE Hôtel Travel</span>
                  </li>
                </ul>
              </div>
              {store.searchResultsHotels.length < 1 ? (
                <div className="search-map search-map-full">
                  <Map type="search" />
                </div>
              ) : (
                <div className="search-grid">
                  <Results data={store.searchResultsHotels} />
                  <div className="search-map">
                    <ResultsMap
                      hotels={store.searchResultsHotels}
                      type="search"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default ResultsTemplate;

export const query = graphql`
  query ($id: String!, $locale: String!) {
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulPage(
      contentful_id: { eq: "6r059oXfU3WqcezyGYSCEA" }
      node_locale: { eq: $locale }
    ) {
      title
      thumbnail {
        fluid(maxWidth: 768, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    allContentfulHotel(
      filter: { slug: { ne: "enseigne" }, node_locale: { eq: $locale } }
    ) {
      nodes {
        title
        phone
        slug
        node_locale
        lat
        lon
        booking_id
        address
        id
        spirit {
          title
          slug
          logo {
            fluid(maxWidth: 100, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        hero_image {
          fluid(maxWidth: 768, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
