import React from "react";

const Pin = ({ variant }) => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 15.5C6 15.5 12 9.94551 12 6.58491C12 3.22431 9.31371 0.5 6 0.5C2.68629 0.5 0 3.22431 0 6.58491C0 9.94551 6 15.5 6 15.5Z"
      fill={variant === "travel" ? "#ED6D3C" : "#0B2246"}
    />
  </svg>
);

export default Pin;
