import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import { useIntl } from "react-intl";
import Img from "gatsby-image";
import mapboxgl from "mapbox-gl";

import Context from "./context";
import Close from "./close";
import Search from "./search";

mapboxgl.accessToken = `${process.env.GATSBY_MAPBOX_ACCESS_TOKEN}`;

const Map = ({ type }) => {
  const { data } = useContext(Context);
  const intl = useIntl();
  const allPages = data.allContentfulPage.nodes;
  const [mapModal, setMapModal] = useState("");
  const [hotelsList] = useState(data.allContentfulHotel.nodes);
  const [hotelModal, setHotelModal] = useState("");
  const [hotelInfos, setHotelInfos] = useState("");
  const [search, setSearch] = useState("");
  const [bookModal, setBookModal] = useState("");

  const params = new URLSearchParams({
    id_etab: hotelInfos.booking_id,
    langue: intl.locale === "fr" ? "francais" : "anglais",
  }).toString();

  const url = `https://reservation.ace-hotel.com/module_booking_engine/index.php?${params}`;

  function book(event) {
    event.preventDefault();

    const url = event.target.href;

    if (window.dataLayer) {
      window.dataLayer.push({
        event: "begin_checkout",
        event_category: "outbound",
        eventCallback: () => {
          window.open(url, "_blank");
        },
        eventTimeout: 2000,
      });
    } else {
      window.open(url, "_blank");
    }
  }

  function getSlug(category) {
    return allPages.find((page) => page.category === category).slug;
  }

  function toggleMapModal() {
    setMapModal(mapModal === "" ? " active" : "");
  }

  function openHotelModal(feature) {
    setHotelInfos({
      name: feature.name,
      address: feature.address,
      phone: feature.phone,
      booking_id: feature.booking_id,
      thumbnail: feature.thumbnail,
      hero_image: feature.hero_image,
      spirit: feature.spirit,
      slug: feature.slug,
      id: feature.id,
    });
    setHotelModal(" active");
  }

  function closeHotelModal() {
    setHotelInfos("");
    setHotelModal("");
  }

  function toggleSearch() {
    setSearch(search === "" ? " active" : "");
  }

  function toggleBookModal() {
    setBookModal(bookModal === "" ? " active" : "");
  }

  useEffect(() => {
    const places = [];

    hotelsList.forEach((element) => {
      const formattedStringAddress = element.address.split(",").join("<br />");
      const newGeoJsonPoint = {
        type: "Feature",
        properties: {
          name: element.title,
          address: formattedStringAddress,
          phone: element.phone,
          booking_id: element.booking_id,
          thumbnail: element.thumbnail,
          hero_image: element.hero_image,
          spirit: element.spirit,
          slug: element.slug,
          id: element.id,
        },
        geometry: {
          type: "Point",
          coordinates: [element.lon, element.lat],
        },
      };
      places.push(newGeoJsonPoint);
    });

    const geojson = {
      type: "FeatureCollection",
      features: places,
    };

    const options = {
      container: "map",
      style: `${process.env.GATSBY_MAPBOX_STYLE}`,
      center: [2.97, 46.79],
      zoom: 5,
    };

    const map = new mapboxgl.Map(options);

    map.scrollZoom.disable();
    map.addControl(new mapboxgl.NavigationControl());

    geojson.features.forEach(function (feature) {
      // create a HTML element for each feature
      const el = document.createElement("div");
      el.className = "map-marker-" + feature.properties.spirit.slug;
      // make a marker for each feature and add to the map
      el.addEventListener("click", () => {
        openHotelModal(feature.properties);
        const coords = feature.geometry.coordinates;
        map.flyTo({
          center: coords,
          speed: 0.2,
        });
      });
      new mapboxgl.Marker(el)
        .setLngLat(feature.geometry.coordinates)
        .addTo(map);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="map">
        <div className="map-content" id="map-browser">
          <div className={`modal modal-map modal-map-mobile${mapModal}`}>
            <Img
              className="modal-map-image"
              fluid={data.contentfulPage.thumbnail.fluid}
              loading="eager"
              alt={data.contentfulPage.thumbnail.title}
            />
            <button className="modal-map-trigger" onClick={toggleMapModal}>
              <span className="button">
                {intl.formatMessage({ id: "map.open" })}
              </span>
            </button>
            <div className="modal-content">
              <div id="map" />
              <button
                className="modal-close"
                onClick={toggleMapModal}
                aria-label={intl.formatMessage({ id: "close" })}
              >
                <Close />
              </button>
            </div>
            <button
              className="modal-backdrop"
              onClick={toggleMapModal}
              aria-label={intl.formatMessage({ id: "close" })}
            ></button>
          </div>
          <div className={`modal modal-hotel${hotelModal}`}>
            <div className="modal-content">
              {hotelModal === " active" && (
                <>
                  <div className="modal-scroll">
                    <div className="modal-hotel-main">
                      {(hotelInfos.hero_image || hotelInfos.thumbnail) && (
                        <figure className="position-relative">
                          <Img
                            fluid={
                              hotelInfos.hero_image
                                ? {
                                    ...hotelInfos.hero_image.fluid,
                                    aspectRatio: 416 / 288,
                                  }
                                : {
                                    ...hotelInfos.thumbnail.fluid,
                                    aspectRatio: 416 / 288,
                                  }
                            }
                            alt={`ACE Hôtel${
                              hotelInfos.spirit &&
                              hotelInfos.spirit.slug === "travel"
                                ? " Travel"
                                : ""
                            } ${hotelInfos.name}`}
                          />
                          {hotelInfos.spirit.logo && (
                            <Img
                              className="logo logo-absolute"
                              fluid={hotelInfos.spirit.logo.fluid}
                              alt={hotelInfos.spirit.title}
                            />
                          )}
                        </figure>
                      )}
                      <h2 className="h3">{`ACE Hôtel${
                        hotelInfos.spirit && hotelInfos.spirit.slug === "travel"
                          ? " Travel"
                          : ""
                      } ${hotelInfos.name}`}</h2>
                      <p className="small-md font-weight-bold text-blue-dark mb-2">
                        {intl.formatMessage({ id: "map.address" })}
                      </p>
                      <p
                        className="mt-2"
                        dangerouslySetInnerHTML={{ __html: hotelInfos.address }}
                      ></p>
                      {hotelInfos.phone && (
                        <>
                          <p className="small-md font-weight-bold text-blue-dark mt-4 mt-md-6 mb-2">
                            {intl.formatMessage({ id: "map.phone" })}
                          </p>
                          <p className="mt-2">
                            <a
                              className="link link-tel"
                              href={`tel:${hotelInfos.phone.replace(
                                /\s/g,
                                ""
                              )}`}
                            >
                              {hotelInfos.phone}
                            </a>
                          </p>
                        </>
                      )}
                      <p className="mt-6">
                        <a
                          className="d-md-none"
                          href={`https://www.ace-hotel-${hotelInfos.slug}.com/`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {intl.formatMessage({ id: "visit" })}
                        </a>
                      </p>
                    </div>
                    <div className="modal-hotel-footer">
                      <a
                        className="link d-none d-md-inline-block"
                        href={`https://www.ace-hotel-${hotelInfos.slug}.com/`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {intl.formatMessage({ id: "visit" })}
                      </a>
                      {type === "search" ? (
                        hotelInfos.booking_id ? (
                          <a
                            href={url}
                            className="button ml-md-6"
                            onClick={book}
                          >
                            {intl.formatMessage({ id: "book" })}
                          </a>
                        ) : (
                          <button
                            className="button ml-md-6"
                            onClick={toggleBookModal}
                          >
                            {intl.formatMessage({ id: "book" })}
                          </button>
                        )
                      ) : (
                        <button
                          className="button ml-md-6"
                          onClick={
                            hotelInfos.booking_id
                              ? toggleSearch
                              : toggleBookModal
                          }
                        >
                          {intl.formatMessage({ id: "book" })}
                        </button>
                      )}
                    </div>
                  </div>
                  <button
                    className="modal-close"
                    onClick={closeHotelModal}
                    aria-label={intl.formatMessage({ id: "close" })}
                  >
                    <Close />
                  </button>
                </>
              )}
            </div>
            <button
              className="modal-backdrop"
              onClick={closeHotelModal}
              aria-label={intl.formatMessage({ id: "close" })}
            ></button>
          </div>
        </div>
      </div>

      <div className={`modal modal-search${search}`}>
        <div className="modal-content">
          <button
            className="modal-close"
            onClick={toggleSearch}
            aria-label={intl.formatMessage({ id: "close" })}
          >
            <Close />
          </button>
          <div className="container">
            {hotelInfos.name && hotelInfos.booking_id ? (
              <Search
                preselectedQuery={hotelInfos.name}
                preselectedBookingId={hotelInfos.booking_id}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <button
          className="modal-backdrop"
          onClick={toggleSearch}
          aria-label={intl.formatMessage({ id: "close" })}
        ></button>
      </div>

      <div className={`modal modal-book${bookModal}`}>
        <div className="modal-content">
          <div className="container">
            <div className="row justify-content-center h-100">
              <div className="col-md-10 col-lg-8 col-xl-6">
                <div className="modal-book-inner">
                  <button
                    className="modal-close"
                    onClick={toggleBookModal}
                    aria-label={intl.formatMessage({ id: "close" })}
                  >
                    <Close />
                  </button>
                  <h3>{intl.formatMessage({ id: "book-modal.heading" })}</h3>
                  <p className="small-md">
                    {intl.formatMessage({ id: "book-modal.paragraph" }) + " "}
                    <Link to={`/${intl.locale}/${getSlug("Contact")}/`}>
                      {intl.formatMessage({ id: "book-modal.link" })}
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="modal-backdrop"
          aria-label={intl.formatMessage({ id: "close" })}
          onClick={toggleBookModal}
        ></button>
      </div>
    </>
  );
};

export default Map;
