import React, { useEffect, useState } from "react";
import { FormattedMessage, FormattedDate, useIntl } from "react-intl";

import Search from "./search";
import Close from "./close";

const SearchData = ({ data }) => {
  const intl = useIntl();
  const [search, setSearch] = useState("");

  useEffect(() => {
    const search = document.getElementById("search");
    const handleSubmit = () => setSearch("");
    search.addEventListener("submit", handleSubmit);
    return () => search.removeEventListener("submit", handleSubmit);
  }, []);

  function toggleSearch() {
    setSearch(search === "" ? " active" : "");
  }

  return (
    <>
      <button
        className="search-data"
        onClick={toggleSearch}
        aria-label={intl.formatMessage({ id: "search" })}
      >
        <table className="search-data-table">
          <tbody>
            <tr className="search-data-row">
              <td className="search-data-cell search-data-cell-location">
                <p>
                  {data.searchQuery.value
                    ? data.searchQuery.value
                    : intl.formatMessage({ id: "form.query" })}
                </p>
              </td>
              <td className="search-data-cell search-data-cell-date">
                <p>
                  {data.startDate ? (
                    <FormattedDate
                      value={data.startDate}
                      day="numeric"
                      month="long"
                      year="numeric"
                    />
                  ) : (
                    <FormattedMessage id="form.arrival" />
                  )}{" "}
                  –{" "}
                  {data.endDate ? (
                    <FormattedDate
                      value={data.endDate}
                      day="numeric"
                      month="long"
                      year="numeric"
                    />
                  ) : (
                    <FormattedMessage id="form.departure" />
                  )}
                </p>
              </td>
              <td className="search-data-cell search-data-cell-coupon-code">
                <p>
                  {data.couponCode || (
                    <FormattedMessage id="form.coupon-code" />
                  )}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </button>
      <div className={`modal modal-search${search}`}>
        <div className="modal-content">
          <button
            className="modal-close"
            onClick={toggleSearch}
            aria-label={intl.formatMessage({ id: "close" })}
          >
            <Close />
          </button>
          <div className="container">
            <Search />
          </div>
        </div>
        <button
          className="modal-backdrop"
          onClick={toggleSearch}
          aria-label={intl.formatMessage({ id: "close" })}
        ></button>
      </div>
    </>
  );
};

export default SearchData;
